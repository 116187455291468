import React, {useState} from "react"

import "features/connect/ConnectAnotherWalletModal.css"
import Button from "components/Button/Button"
import TombstoneModal from "components/TombstoneModal/TombstoneModal"
import ConnectWallet from "features/connect/ConnectWallet"
import network from "utils/network"
import {useQueryWithErrorHandling, useMutationWithErrorHandling} from "utils/query"

const walletAuthRequest = () => network.get("/wallet/auth")

const walletConnectRequest = walletData =>
  network.post("/wallet/connect", walletData)

const ConnectAnotherWalletModal = ({visible, onDismiss, onWalletConnect, wallets}) => {
  const [authQueryEnabled, setAuthQueryEnabled] = useState(false)
  const [showConnectWallet, setShowConnectWallet] = useState(false)

  const walletAuth = useQueryWithErrorHandling({
    queryKey: ["wallet", "auth"],
    queryFn: walletAuthRequest,
    enabled: authQueryEnabled,
    onSuccess: () => setShowConnectWallet(true),
    onSettled: () => setAuthQueryEnabled(false),
  })

  const walletConnect = useMutationWithErrorHandling({
    mutationFn: walletConnectRequest,
    onSuccess: () => {
      setShowConnectWallet(false)
      onWalletConnect()
    },
  })

  const handleConnectWallet = data => walletConnect.mutate(data)

  return (
    <TombstoneModal
      isOpen={visible}
      onClose={onDismiss}
      contentClassName="connect-another-wallet-modal"
      diamondImage="connect">
      {showConnectWallet ? (
        <ConnectWallet
          title="Connect additional wallets"
          nonce={walletAuth.data?.body?.nonce}
          isConnectingWallet={walletConnect.isPending}
          onWalletConnect={handleConnectWallet}
        />
      ) : (
        <>
          <h2 className="connect-another-wallet-modal__title">
            Connect additional wallets
          </h2>
          <div className="connect-another-wallet-modal__wallets">
            {wallets.map(({address}, idx) => (
              <div
                key={`wallet-${idx}`}
                className="connect-another-wallet-modal__wallet-row">
                <div className="connect-another-wallet-modal__wallet">
                  {address}
                </div>
                <div className="connect-another-wallet-modal__connected">
                  connected
                </div>
              </div>
            ))}
          </div>
          <Button
            onClick={() => setAuthQueryEnabled(true)}
            disabled={authQueryEnabled}
            loading={authQueryEnabled}
            isSecondary>
            Connect another wallet
          </Button>
        </>
      )}
    </TombstoneModal>
  )
}

export default ConnectAnotherWalletModal
