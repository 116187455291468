import React from "react"
import {useQueryClient} from "@tanstack/react-query"

import "features/offer/BurnTrackerPage.css"
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner"
import PageContainer from "components/PageContainer/PageContainer"
import PageHeader from "components/PageHeader/PageHeader"
import BurnHistory from "features/offer/BurnHistory"
import BurnTrackerOven from "features/offer/BurnTrackerOven"
import network from "utils/network"
import {useQueryWithErrorHandling} from "utils/query"

const burnTrackerRequest = () => network.get("/offer/burn-tracker")

const BurnTrackerPage = () => {
  const queryClient = useQueryClient()

  const {data, isLoading, isError} = useQueryWithErrorHandling({
    queryKey: ["offer", "burn-tracker"],
    queryFn: burnTrackerRequest,
  })

  const invalidateBurnTrackerQuery = () => {
    queryClient.invalidateQueries({queryKey: ["offer", "burn-tracker"]})
  }

  if (isLoading) {
    return (
      <PageContainer className="burn-tracker-page__loading">
        <LoadingSpinner />
      </PageContainer>
    )
  }

  if (isError) {
    return (
      <PageContainer className="burn-tracker-page__error">
        <p>Oops, something went wrong!</p>
      </PageContainer>
    )
  }

  const {
    header,
    title,
    flamesImg,
    flamesAni,
    amountToBeBurned,
    amountAlreadyBurned,
    burnTime,
    history,
  } = data.body

  return (
    <PageContainer>
      <PageHeader
        {...header}
        onWalletConnect={invalidateBurnTrackerQuery}
      />
      <div className="burn-tracker-page__container">
        <div className="burn-tracker-page__inner-container">
          <div className="burn-tracker-page__header">
            <img
              className="burn-tracker-page__flames-img"
              alt="Chrome flames"
              src={flamesImg}
              draggable="false"
            />
            <h1 className="burn-tracker-page__title">{title}</h1>
          </div>
          <div className="burn-tracker-page__cards">
            <BurnTrackerOven
              amountToBeBurned={amountToBeBurned}
              flamesAni={flamesAni}
              burnTime={burnTime}
            />
            <div className="burn-tracker-page__secondary-card">
              <h3 className="burn-tracker-page__secondary-card-title">
                {amountAlreadyBurned} $HM
              </h3>
              <div className="burn-tracker-page__secondary-card-subtitle">
                Total Holdium burned to date
              </div>
            </div>
          </div>
          <BurnHistory entries={history} />
        </div>
      </div>
    </PageContainer>
  )
}

export default BurnTrackerPage
