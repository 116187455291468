import React from "react"
import {FireSimple} from "@phosphor-icons/react"

import "features/home/HomeDropSummary.css"
import TextWithIcon from "components/TextWithIcon/TextWithIcon"

const DropSummaryForHolder = ({
  allocated,
  dropped,
  toBeDropped,
  dropDate,
  dropPercentage,
}) => (
  <div className="drop-summary__cards">
    <div className="drop-summary-card">
      <h3 className="drop-summary-card__title">{dropped} $HM</h3>
      <div className="drop-summary-card__subtitle">Received so far</div>
    </div>
    <div className="drop-summary-card">
      <h3 className="drop-summary-card__title">{toBeDropped} $HM</h3>
      <div className="drop-summary-card__subtitle">
        Next drop ({dropPercentage}%): {dropDate}
      </div>
    </div>
    <div className="drop-summary-card">
      <h3 className="drop-summary-card__title">{allocated} $HM</h3>
      <div className="drop-summary-card__subtitle">Total allocation</div>
    </div>
  </div>
)

const DropSummaryForJeeter = ({dropped, toBeDropped, burned}) => (
  <div className="drop-summary__cards">
    <div className="drop-summary-card">
      <h3 className="drop-summary-card__title">{dropped} $HM</h3>
      <div className="drop-summary-card__subtitle">Received so far</div>
    </div>
    <div className="drop-summary-card">
      <h3 className="drop-summary-card__title">{toBeDropped} $HM</h3>
      <div className="drop-summary-card__subtitle">Next drop: -</div>
    </div>
    <div className="drop-summary-card">
      <h3 className="drop-summary-card__title">{burned} $HM</h3>
      <TextWithIcon
        className="drop-summary-card__subtitle"
        text="Total burned"
        iconLeft={<FireSimple size={16} weight="fill" />}
      />
    </div>
  </div>
)

const HomeDropSummary = (props) => {
  const {burned} = props

  return burned
    ? <DropSummaryForJeeter {...props} />
    : <DropSummaryForHolder {...props} />
}

export default HomeDropSummary
