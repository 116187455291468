import React, {useEffect, useRef} from "react"
import {X} from "@phosphor-icons/react"
import classNames from "classnames"

import "components/TombstoneModal/TombstoneModal.css"

const staticImages = {
  connect:
    "https://imagedelivery.net/gxyfIvbwQzmXmfQxBzDUvA/6691c570-258e-45bb-e98e-3035cb91d100/public",
  withdraw:
    "https://imagedelivery.net/gxyfIvbwQzmXmfQxBzDUvA/681f4d33-5630-4c53-0a4c-3c444a982800/public",
  envelope:
    "https://imagedelivery.net/gxyfIvbwQzmXmfQxBzDUvA/fb4df4cb-8eb4-4092-da77-d2396599a400/public",
  moneybag:
    "https://imagedelivery.net/gxyfIvbwQzmXmfQxBzDUvA/931272fd-f752-4432-2125-0f9bcedc6400/public",
}

const TombstoneModal = ({
  isOpen,
  onClose,
  dismissable = false,
  children,
  contentClassName,
  diamondImage,
}) => {
  const modalRef = useRef(null)

  useEffect(() => {
    const modalElement = modalRef.current
    if (isOpen) {
      modalElement.showModal()
    }
  }, [isOpen])

  const handleCloseModal = () => {
    const modalElement = modalRef.current
    onClose()
    modalElement.close()
  }

  const handleKeyDown = e => {
    if (e.key === "Escape" && dismissable) {
      handleCloseModal()
    } else if (e.key === "Escape" && !dismissable) {
      e.preventDefault()
    }
  }

  return (
    <dialog
      ref={modalRef}
      onKeyDown={handleKeyDown}
      className={classNames({
        "tombstone-modal": true,
        "tombstone-modal--open": isOpen,
      })}>
      {isOpen && (
        <>
          <div className="tombstone-modal__container">
            <div className="tombstone-modal__header">
              <X
                onClick={handleCloseModal}
                size={24}
                className="tombstone-modal__close-button"
              />
            </div>
            {diamondImage ? (
              <div className="tombstone-modal__diamond-shape">
                <img
                  className="tombstone-modal__diamond-shape-inner-image"
                  draggable={false}
                  src={
                    staticImages[diamondImage]
                      ? staticImages[diamondImage]
                      : diamondImage
                  }
                />
              </div>
            ) : null}
            <div className="tombstone-modal__arch">
              <div
                className={classNames(
                  "tombstone-modal__content",
                  contentClassName,
                )}>
                {children}
              </div>
            </div>
          </div>
        </>
      )}
    </dialog>
  )
}

export default TombstoneModal
