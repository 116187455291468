import React from "react"
import classNames from "classnames"

import "components/TextInput/TextInput.css"

const TextInput = ({
  placeholder = "",
  value = "",
  setValue,
  error = "",
  clearError = () => {},
  disabled = false,
  className,
  inputClassName,
}) => {
  const handleTextChange = e => {
    if (error) {
      clearError()
    }
    setValue(e.target.value)
  }

  return (
    <div className={classNames(
      "text-input-container",
      className,
    )}>
      <input
        className={classNames(
          "text-input",
          {"text-input--error": error},
          inputClassName,
        )}
        type="text"
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={handleTextChange}
      />
      {error && <div className="text-input__error">{error}</div>}
    </div>
  )
}

export default TextInput
