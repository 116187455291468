import React from "react"
import {Spinner} from "@phosphor-icons/react"

import colors from "assets/colors"

const LoadingSpinner = ({size = 64}) => (
  <Spinner size={size} color={colors.mrGhost}>
    <animateTransform
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      dur="1s"
      from="0 0 0"
      to="360 0 0"
      repeatCount="indefinite"></animateTransform>
  </Spinner>
)

export default LoadingSpinner
