import React from "react"
import riveWASMResource from "@rive-app/canvas/rive.wasm"
import RiveComponent, {RuntimeLoader, Layout, Fit, Alignment} from "@rive-app/react-canvas"

import "features/offer/BurnTrackerOven.css"
import flameSmallRiv from "assets/animations/flame_small.riv"
import flameMediumRiv from "assets/animations/flame_medium.riv"
import flameLargeRiv from "assets/animations/flame_large.riv"
import flameLargeLightningRiv from "assets/animations/flame_large_lightning.riv"
import Countdown, {clockToDayFormatter} from "components/Countdown/Countdown"

RuntimeLoader.setWasmUrl(riveWASMResource)

const aniToRivFile = {
  "small": flameSmallRiv,
  "medium": flameMediumRiv,
  "large": flameLargeRiv,
  "extra-large": flameLargeLightningRiv,
}

const BurnTrackerOven = ({amountToBeBurned, flamesAni, burnTime}) => {
  const rivAni = aniToRivFile[flamesAni] || flameSmallRiv

  return (
    <div className="burn-tracker-oven">
      <div className="burn-tracker-oven__countdown-label">
        Upcoming Burn
      </div>
      <div className="burn-tracker-oven__card">
        <h2 className="burn-tracker-oven__title">
          {amountToBeBurned} $HM
        </h2>
        <div className="burn-tracker-oven__subtitle">
          TO BE BURNED
        </div>
        <div className="burn-tracker-oven__riv-ani">
          <RiveComponent
            src={rivAni}
            layout={new Layout({fit: Fit.FitWidth, alignment: Alignment.BottomCenter})}
            autoPlay
          />
        </div>
        <Countdown
          className="burn-tracker-oven__countdown"
          targetTime={new Date(burnTime)}
          formatter={clockToDayFormatter}
          expiredText="00:00:00:00"
        />
      </div>
    </div>
  )
}

export default BurnTrackerOven
