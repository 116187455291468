import {merge} from "lodash/fp"

export const API_BASE_URL = __DEV__
  ? "http://localhost:8080/api"
  : "https://app.holdium.xyz/api"

class FetchError extends Error {
  constructor(status, headers, body, ...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError)
    }

    this.name = "FetchError"
    this.res = {status, headers, body}
  }
}

const get = async (route, options = {}) => {
  const methodOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }
  return await requestHandler(route, merge(methodOptions)(options))
}

const post = async (route, body, options = {}) => {
  const methodOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
  return await requestHandler(route, merge(methodOptions)(options))
}

const _delete = async (route, body, options = {}) => {
  const methodOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
  return await requestHandler(route, merge(methodOptions)(options))
}

// This function ensures that parsing the body of a response will
// always return a valid JSON.
const jsonfy = async response => {
  try {
    return await response.json()
  } catch (e) {
    return {}
  }
}

const requestHandler = async (route, options) => {
  const res = await fetch(`${API_BASE_URL}${route}`, options)
  const status = res.status
  const headers = res.headers
  const body = await jsonfy(res)

  if (!res.ok) {
    throw new FetchError(
      status,
      headers,
      body,
      `Network response failed: ${res.statusText}`,
    )
  }

  return {status, headers, body}
}

const network = {
  get,
  post,
  delete: _delete,
}

export default network
