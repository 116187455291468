import React, {useEffect, useState} from "react"

const MILLIS_IN_SECOND = 1000
const MILLIS_IN_MINUTE = MILLIS_IN_SECOND * 60
const MILLIS_IN_HOUR   = MILLIS_IN_MINUTE * 60
const MILLIS_IN_DAY    = MILLIS_IN_HOUR * 24
const MILLIS_IN_YEAR   = MILLIS_IN_DAY * 365

const addLeadingZero = n => `${n < 10 ? "0" : ""}${n}`

export const defaultFormatter = (years, days, hours, minutes, seconds) => {
  const displayYears = years > 0 ? `${years}y` : null
  const displayDays = displayYears || days > 0 ? `${days}d` : null
  const displayHours = displayDays || hours > 0 ? `${hours}h` : null
  const displayMinutes = displayHours || minutes > 0 ? `${minutes}m` : null
  const displaySeconds = !displayHours ? `${seconds}s` : null

  return [displayYears, displayDays, displayHours, displayMinutes, displaySeconds]
    .filter(x => x)
    .join(" ")
}

export const clockToDayFormatter = (years, days, hours, minutes, seconds) => {
  return `${addLeadingZero(days)}:${addLeadingZero(hours)}:${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`
}

const breakdownRemainingTime = (remaining) => {
  const seconds = Math.floor((remaining / MILLIS_IN_SECOND) % 60)
  const minutes = Math.floor((remaining / MILLIS_IN_MINUTE) % 60)
  const hours = Math.floor((remaining / MILLIS_IN_HOUR) % 24)
  const days = Math.floor((remaining / MILLIS_IN_DAY) % 365)
  const years = Math.floor(remaining / MILLIS_IN_YEAR)

  return [years, days, hours, minutes, seconds]
}

const Countdown = ({
  className,
  targetTime,
  labelText,
  expiredText = "Expired",
  onExpired,
  formatter,
}) => {
  const [time, setTime] = useState(targetTime - Date.now())
  const formatFn = formatter || defaultFormatter
  const formatted = formatFn(...breakdownRemainingTime(time))
  const text = labelText ? `${labelText} ${formatted}` : formatted

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(targetTime - Date.now())
    }, 1000)

    return () => clearInterval(interval)
  }, [targetTime])

  useEffect(() => {
    if (time < 0 && onExpired) {
      onExpired()
    }
  }, [time])

  return (
    <div className={className}>
      {time > 0 ? text : expiredText}
    </div>
  )
}

export default Countdown
