import React from "react"
import classNames from "classnames"

import "features/home/HomeHoldiumCard.css"

const HomeHoldiumCard = ({title, accountPoints, subtitle, dropWalletAddress, image}) => (
  <div className={classNames("home-holdium-card", "scooped")}>
    <div className="home-holdium-card__content-left">
      {dropWalletAddress && (
        <div className="home-holdium-card__drop-wallet">
          Drop wallet: {dropWalletAddress}
        </div>
      )}
      <div className="home-holdium-card__title-wrapper">
        <div className="home-holdium-card__title">
          {title}
        </div>
        <div className="home-holdium-card__points">
          {accountPoints}
        </div>
      </div>
      <div className="home-holdium-card__subtitle">
        {subtitle}
      </div>
    </div>
      <div className="home-holdium-card__content-right">
        <img className="home-holdium-card__image" src={image} />
      </div>
  </div>
)

export default HomeHoldiumCard
