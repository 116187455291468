import React, {useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import {useWallet} from "@solana/wallet-adapter-react"
import riveWASMResource from '@rive-app/canvas/rive.wasm'
import RiveComponent, {RuntimeLoader} from '@rive-app/react-canvas'
import {ArrowsClockwise} from "@phosphor-icons/react"

import "features/connect/ConnectWallet.css"
import connectingWalletRiv from "assets/animations/connecting_wallet.riv"
import Button from "components/Button/Button"
import {consoleError} from "utils/generic"
import {shortFormat} from "utils/wallet"

RuntimeLoader.setWasmUrl(riveWASMResource)

const ConnectWallet = ({
  title = "Connect wallet",
  nonce,
  isConnectingWallet,
  onWalletConnect,
  onBackClick,
}) => {
  const {publicKey, wallet, wallets, select, signMessage} = useWallet()
  const [isSigning, setIsSigning] = useState(false)
  const navigate = useNavigate()

  const handleConnectWallet = async () => {
    try {
      if (!nonce || !publicKey) {
        return
      }
      setIsSigning(true)
      const encodedNonce = new TextEncoder().encode(nonce)
      const signature = await signMessage(encodedNonce)
      onWalletConnect({
        address: publicKey?.toString(),
        signature: btoa(String.fromCharCode(...signature)),
        nonce,
      })
    } catch (err) {
      consoleError(err)
    } finally {
      setIsSigning(false)
    }
  }

  return (
    <div className="connect-wallet__container">
      {isConnectingWallet ? (
        <div className="connect-wallet__riv-ani">
          <RiveComponent
            src={connectingWalletRiv}
            autoPlay
          />
        </div>
      ) : wallet ? (
        <>
          <h2 className="connect-wallet__title">{title}</h2>
          {publicKey ? (
            <div className="connect-wallet__address-container">
              <div className="connect-wallet__address">
                {shortFormat(publicKey.toString(), "solana")}
              </div>
              <Button
                className="connect-wallet__button"
                isSecondary
                onClick={handleConnectWallet}
                disabled={isSigning}
                loading={isSigning}>
                <img
                  className="connect-wallet__button-icon"
                  src={wallet.adapter.icon}
                  draggable={false}
                />
                <span className="connect-wallet__button-label">
                  Connect this wallet
                </span>
              </Button>
            </div>
          ) : (
            <div className="connect-wallet__loading">
              Connecting to {wallet.adapter.name}...
            </div>
          )}
          <div className="connect-wallet__back">
            <a
              className="connect-wallet__link"
              onClick={() => select(null)}>
              Use another wallet provider
            </a>
         </div>
        </>
      ) : (
        <>
          <h2 className="connect-wallet__title">{title}</h2>
          {wallets?.length > 0 ? (
            <div className="connect-wallet__buttons">
              {wallets.map((wallet, idx) => (
                <Button
                  key={`wallet-${idx}`}
                  className="connect-wallet__button"
                  onClick={() => select(wallet.adapter.name)}
                  isSecondary>
                  <span className="connect-wallet__button-label">{wallet.adapter.name}</span>
                  <img
                    className="connect-wallet__button-icon"
                    src={wallet.adapter.icon}
                    draggable={false}
                  />
                </Button>
              ))}
              <Button
                key={`wallet-ledger`}
                className="connect-wallet__button"
                onClick={() => navigate("/check")}
                isSecondary>
                <span className="connect-wallet__button-label">Ledger</span>
              </Button>
            </div>
          ) : (
            <div className="connect-wallet__no-wallet">
              <div>No wallet detected.</div>
              <div>Make sure your wallet extension is enabled.</div>
              <Button
                className="connect-wallet__button"
                onClick={() => window.location.reload()}
                isSecondary>
                <ArrowsClockwise size={20} />
                <span>Refresh</span>
              </Button>
              <Button
                key={`wallet-ledger`}
                className="connect-wallet__button"
                onClick={() => navigate("/check")}
                isSecondary>
                <span className="connect-wallet__button-label">Ledger</span>
              </Button>              
            </div>
          )}
          <Link
            className="connect-wallet__check-wallet-link"
            to="/check">
            View points for wallets registered without connecting.
          </Link>
          {onBackClick && (
            <div className="connect-wallet__back">
              <a
                className="connect-wallet__link"
                onClick={onBackClick}>
                Back
              </a>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ConnectWallet
