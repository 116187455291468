export const shortFormat = fullAddress => {
  if (!fullAddress) {
    return
  }

  const CHUNK_SIZE = 4

  if (fullAddress.length <= CHUNK_SIZE * 2) {
    return fullAddress
  }

  const startChunk = fullAddress.substring(0, CHUNK_SIZE)
  const endChunk = fullAddress.substring(fullAddress.length - CHUNK_SIZE)

  return `${startChunk}...${endChunk}`
}
