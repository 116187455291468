import React, {useState} from "react"
import {useNavigate} from "react-router-dom"
import classNames from "classnames"
import {StarFour} from "@phosphor-icons/react"

import "features/connect/ConnectWalletPage.css"
import Button from "components/Button/Button"
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner"
import PageContainer from "components/PageContainer/PageContainer"
import TextWithIcon from "components/TextWithIcon/TextWithIcon"
import ConnectWalletModal from "features/connect/ConnectWalletModal"
import BurnTrackerOven from "features/offer/BurnTrackerOven"
import network from "utils/network"
import {useQueryWithErrorHandling, useMutationWithErrorHandling} from "utils/query"

const connectRequest = () => network.get("/connect")

const walletAuthRequest = () => network.get("/wallet/auth")

const walletConnectRequest = walletData =>
  network.post("/wallet/connect", walletData)

const ConnectWalletPage = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [authQueryEnabled, setAuthQueryEnabled] = useState(false)
  const navigate = useNavigate()

  const {isLoading, isError, data} = useQueryWithErrorHandling({
    queryKey: ["connect"],
    queryFn: connectRequest,
  })

  const walletAuth = useQueryWithErrorHandling({
    queryKey: ["wallet", "auth"],
    queryFn: walletAuthRequest,
    enabled: authQueryEnabled,
    onSuccess: () => setModalVisible(true),
    onSettled: () => setAuthQueryEnabled(false),
  })

  const walletConnect = useMutationWithErrorHandling({
    mutationFn: walletConnectRequest,
    onSuccess: res => {
      const {body} = res
      navigate(body.path)
    },
  })

  const handleConnectWallet = data => walletConnect.mutate(data)

  if (isLoading) {
    return (
      <PageContainer className="connect-wallet-page__center-content">
        <LoadingSpinner />
      </PageContainer>
    )
  }

  if (isError) {
    return (
      <PageContainer className="connect-wallet-page__center-content">
        <p>Oops, something went wrong!</p>
      </PageContainer>
    )
  }

  const {
    logoUrl,
    logoShadowUrl,
    title,
    subtitle,
    connectCta,
    leaderboardCta,
    burnCta,
    amountToBeBurned,
    flamesAni,
    burnTime,
    percentageBurned,
  } = data.body

  return (
    <PageContainer className="connect-wallet-page__container">
      <div className="connect-wallet-page__content">
        <div className="connect-wallet-page__main">
          <div className="connect-wallet-page__logo-container">
            <div className="connect-wallet-page__logo">
              <img
                className="connect-wallet-page__logo-shadow-img"
                alt="Holdium logo shadow"
                src={logoShadowUrl}
                draggable="false"
              />
              <img
                className="connect-wallet-page__logo-img"
                alt="Holdium logo"
                src={logoUrl}
                draggable="false"
              />
            </div>
          </div>
          <div className="connect-wallet-page__card-with-buttons">
            <div className={classNames("connect-wallet-page__card", "scooped")}>
              <h1 className="connect-wallet-page__card-title">
                {title}
              </h1>
              <TextWithIcon
                textClassName="connect-wallet-page__card-subtitle-text"
                text={subtitle}
                iconLeft={<StarFour size={16} weight="fill" />}
                iconLeftClassName="connect-wallet-page__card-subtitle-icon"
              />
            </div>
            <div className="connect-wallet-page__main-buttons">
              <Button
                className="connect-wallet-page__button"
                onClick={() => setAuthQueryEnabled(true)}
                disabled={authQueryEnabled}
                loading={authQueryEnabled}>
                {connectCta}
              </Button>
              <Button
                className="connect-wallet-page__button"
                onClick={() => navigate("/leaderboard")}
                isSecondary>
                {leaderboardCta}
              </Button>
            </div>
          </div>
        </div>
        <div className="connect-wallet-page__burn">
          <h2 className="connect-wallet-page__perc-burned">
            Supply burned to date: {percentageBurned}%
          </h2>
          <BurnTrackerOven
            amountToBeBurned={amountToBeBurned}
            flamesAni={flamesAni}
            burnTime={burnTime}
          />
          <div className="connect-wallet-page__burn-button-container">
            <Button
              className="connect-wallet-page__button"
              onClick={() => navigate("/burn-tracker")}
              isSecondary>
              {burnCta}
            </Button>
          </div>
        </div>
        <ConnectWalletModal
          nonce={walletAuth.data?.body?.nonce}
          onDismiss={() => setModalVisible(false)}
          isConnectingWallet={walletConnect.isPending}
          onWalletConnect={handleConnectWallet}
          visible={modalVisible}
        />
      </div>
    </PageContainer>
  )
}

export default ConnectWalletPage
