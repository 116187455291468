import {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {useMutation, useQuery} from "@tanstack/react-query"

const defaultErrorHandler =
  ({navigate}) =>
  err => {
    switch (err?.res?.status) {
      case 401:
        navigate("/connect")
        break
      case 403:
        navigate(err?.res?.body?.path || "/referral-code")
        break
      case 404:
        navigate(err?.res?.body?.path || "/")
        break
    }
  }

const defaultRetryHandler = (failureCount, error) => {
  const code = error?.res?.status
  return code < 400 && code > 499 && failureCount < 3
}

export const useQueryWithErrorHandling = opts => {
  const {onSuccess, onSettled} = opts
  const navigate = useNavigate()

  const query = useQuery({
    retry: defaultRetryHandler,
    ...opts,
  })

  useEffect(() => {
    if (query.data && query.isFetchedAfterMount) {
      if (onSuccess) {
        onSuccess(query.data)
      }
      if (onSettled) {
        onSettled(query.data, query.error)
      }
    }
  }, [query.data, query.isFetchedAfterMount])

  useEffect(() => {
    if (query.error && query.isFetchedAfterMount) {
      defaultErrorHandler({navigate})(query.error)
      if (onSettled) {
        onSettled(query.data, query.error)
      }
    }
  }, [query.error, query.isFetchedAfterMount])

  return query
}

export const useMutationWithErrorHandling = opts => {
  const navigate = useNavigate()

  return useMutation({
    onError: defaultErrorHandler({navigate}),
    retry: defaultRetryHandler,
    ...opts,
  })
}
