import React from "react"

import "features/connect/ConnectWalletModal.css"
import TombstoneModal from "components/TombstoneModal/TombstoneModal"
import ConnectWallet from "features/connect/ConnectWallet"

const ConnectWalletModal = ({
  nonce,
  onDismiss,
  isConnectingWallet,
  onWalletConnect,
  visible,
}) => {
  return (
    <TombstoneModal
      isOpen={visible}
      onClose={onDismiss}
      contentClassName="connect-wallet-modal"
      diamondImage="connect">
      <ConnectWallet
        nonce={nonce}
        isConnectingWallet={isConnectingWallet}
        onWalletConnect={onWalletConnect}
      />
    </TombstoneModal>
  )
}

export default ConnectWalletModal
