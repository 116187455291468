import React, {useState} from "react"
import {useNavigate} from "react-router-dom"
import {useQueryClient} from "@tanstack/react-query"
import classNames from "classnames"

import "features/connect/OnChainConnectPage.css"
import Button from "components/Button/Button"
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner"
import TextInput from "components/TextInput/TextInput"
import PageContainer from "components/PageContainer/PageContainer"
import PageHeader from "components/PageHeader/PageHeader"
import network from "utils/network"
import {useQueryWithErrorHandling, useMutationWithErrorHandling} from "utils/query"
import {shortFormat} from "utils/wallet"

const onChainConnectRequest = () => network.get("/on-chain-connect")

const checkWalletRequest = walletData =>
  network.post("/on-chain-connect/check-wallet", walletData)

const CheckWalletForm = ({
  title,
  placeholder,
  inputValue,
  setInputValue,
  cta,
  onClick
}) => (
  <>
    <div className="oc-connect-page__card-title">
      <div className="oc-connect-page__card-title-number">2</div>
      <h2 className="oc-connect-page__card-title-text">
        {title}
      </h2>
    </div>
    <TextInput
      className="oc-connect-page__text-input-wrapper"
      inputClassName="oc-connect-page__text-input"
      placeholder={placeholder}
      value={inputValue}
      setValue={setInputValue}
    />
    <Button
      className="oc-connect-page__button"
      onClick={onClick}>
      {cta}
    </Button>
  </>
)

const CheckingWallet = () => (
  <>
    <div className="oc-connect-page__checking">
      Checking...
    </div>
    <LoadingSpinner size={32} />
  </>
)

const CheckWalletError = ({address, error, onClick}) => (
  <>
    <div className="oc-connect-page__result-address">
      [{shortFormat(address)}]
    </div>
    <div className="oc-connect-page__result-message">
      {error}
    </div>
    <Button
      className="oc-connect-page__button"
      onClick={onClick}
      isSecondary>
      Check again
    </Button>
    <img
      className="oc-connect-page__result-motif"
      src="https://imagedelivery.net/gxyfIvbwQzmXmfQxBzDUvA/c43713c4-539e-4707-ab43-7f7cd4398900/public"
    />
  </>
)

const CheckWalletPoints = ({address, points}) => (
  <>
    <div className="oc-connect-page__result-address">
      [{shortFormat(address)}]
    </div>
    <div className="oc-connect-page__result-message">You qualify for</div>
    <div className="oc-connect-page__result-points">{points}</div>
    <div className="oc-connect-page__result-message">HOLDIUM POINTS</div>
    <img
      className="oc-connect-page__result-motif"
      src="https://imagedelivery.net/gxyfIvbwQzmXmfQxBzDUvA/c43713c4-539e-4707-ab43-7f7cd4398900/public"
    />
  </>
)

const OnChainConnectPage = () => {
  const [inputValue, setInputValue] = useState("")
  const [checkWalletResult, setCheckWalletResult] = useState(null)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {data, isLoading, isError} = useQueryWithErrorHandling({
    queryKey: ["on-chain-connect"],
    queryFn: onChainConnectRequest,
  })

  const checkWallet = useMutationWithErrorHandling({
    mutationFn: checkWalletRequest,
    onSuccess: res => setCheckWalletResult(res.body),
  })

  const invalidateOnChainConnectQuery = () => {
    queryClient.invalidateQueries({queryKey: ["on-chain-connect"]})
  }

  if (isLoading) {
    return (
      <PageContainer className="oc-connect-page__loading">
        <LoadingSpinner />
      </PageContainer>
    )
  }

  if (isError) {
    return (
      <PageContainer className="oc-connect-page__error">
        <p>Oops, something went wrong!</p>
      </PageContainer>
    )
  }

  const {logoUrl, logoShadowUrl, card1, card2, header} = data.body
  const loggedIn = !!header

  return (
    <PageContainer>
      {header && (
        <PageHeader
          {...header}
          showBackButton
          onClickBack={() => navigate("/")}
          onWalletConnect={invalidateOnChainConnectQuery}
        />
      )}
      <div className={classNames(
        "oc-connect-page__container",
        {"oc-connect-page__container--public": !loggedIn},
      )}>
        <div className="oc-connect-page__logo">
          <img
            className="oc-connect-page__logo-shadow-img"
            alt="Holdium logo shadow"
            src={logoShadowUrl}
            draggable="false"
          />
          <img
            className="oc-connect-page__logo-img"
            alt="Holdium logo"
            src={logoUrl}
            draggable="false"
          />
        </div>
        <div className="oc-connect-page__cards">
          <div className={classNames(
            "oc-connect-page__card",
            "oc-connect-page__card1",
            "scooped",
          )}>
            <div className="oc-connect-page__card-title">
              <div className="oc-connect-page__card-title-number">1</div>
              <h2 className="oc-connect-page__card-title-text">
                {card1.title}
              </h2>
            </div>                        
          </div>
          <div className={classNames(
            "oc-connect-page__card",
            "oc-connect-page__card2",
            "scooped",
          )}>
            {checkWallet.isPending ? (
              <CheckingWallet />
            ) : checkWalletResult?.error ? (
              <CheckWalletError
                {...checkWalletResult}
                onClick={() => setCheckWalletResult(null)}
              />
            ) : (checkWalletResult?.points
                 || (checkWalletResult?.points === 0)) ? (
              <CheckWalletPoints
                {...checkWalletResult}
              />
            ) : (
              <CheckWalletForm
                title={card2.title}
                placeholder={card2.inputPlaceholder}
                inputValue={inputValue}
                setInputValue={setInputValue}
                cta={card2.cta}
                onClick={() => checkWallet.mutate({address: inputValue})}
              />
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default OnChainConnectPage
