import React from "react"
import classNames from "classnames"

import "components/PoweredByBadge/PoweredByBadge.css"

const PoweredByBadge = ({className}) => (
  <div
    className={classNames("powered-by-badge__container", className)}>
    <span>powered by</span>
    <img
      className="powered-by-badge__image"
      src="https://imagedelivery.net/gxyfIvbwQzmXmfQxBzDUvA/db160ae7-29a1-4b66-e43d-87b421c02800/public"
      draggable="false"
    />
  </div>
)

export default PoweredByBadge
