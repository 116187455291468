import React from "react"
import {createBrowserRouter, redirect} from "react-router-dom"
import {wrapCreateBrowserRouter} from "@sentry/react"

import ConnectWalletPage from "features/connect/ConnectWalletPage"
import OnChainConnectPage from "features/connect/OnChainConnectPage"
import HomePage from "features/home/HomePage"
import OfferLeaderboardPage from "features/offer/OfferLeaderboardPage"
import BurnTrackerPage from "features/offer/BurnTrackerPage"
import network from "utils/network"

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const redirectTo = (path, location) =>
  location.pathname === path ? null : redirect(path)

const isPublicPath = path => path == "/connect"

const isConnectPath = path => path == "/connect"

const authLoader = async ({request}) => {
  const location = new URL(request.url)
  try {
    const resp = await network.get("/account")

    if (isPublicPath(location.pathname)) {
      return redirectTo("/", location)
    } else {
      return resp
    }
  } catch (err) {
    switch (err?.res?.status) {
      case 401:
        if (!isConnectPath(location.pathname)) {
          return redirectTo(err?.res?.body?.path || "/connect", location)
        }
        break;
      case 403:
        return redirectTo(err?.res?.body?.path || "/referral-code", location)
    }
  }
  return null
}

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    loader: authLoader,
    element: <HomePage />,
  },
  {
    path: "/connect",
    loader: authLoader,
    element: <ConnectWalletPage />,
  },
  {
    path: "/leaderboard",
    element: <OfferLeaderboardPage />,
  },
  {
    path: "/check",
    element: <OnChainConnectPage />,
  },
  {
    path: "/burn-tracker",
    element: <BurnTrackerPage />
  },
  {
    path: "/*",
    loader: () => redirect("/"),
  },
])
