import React from "react"
import ReactDOM from "react-dom/client"
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom"
import * as Sentry from "@sentry/react"

import App from "App"

if (!__DEV__) {
  Sentry.init({
    dsn: "https://c3457465ea334fc3bfc1233c1fffa44f@o916273.ingest.sentry.io/4505402334380032",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
