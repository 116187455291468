import React from "react"
import {CircleNotch} from "@phosphor-icons/react"
import classNames from "classnames"

import "components/Button/Button.css"

const Button = ({
  children,
  className,
  loading,
  isSecondary = false,
  disabled = false,
  ...buttonProps
}) => (
  <button
    className={classNames(
      {button: true, "button--secondary": isSecondary},
      className,
    )}
    {...buttonProps}
    disabled={loading || disabled}>
    {children}
    {loading && (
      <div className="button__loading">
        <CircleNotch size={18}>
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 0 0"
            to="360 0 0"
            repeatCount="indefinite"></animateTransform>
        </CircleNotch>
      </div>
    )}
  </button>
)

export default Button
