import React from "react"
import {ArrowSquareOut} from "@phosphor-icons/react"
import classNames from "classnames"

import "features/offer/BurnHistory.css"

const BurnTxLink = ({className, url}) => {
  const u = new URL(url)
  const prettyUrl = `${u.hostname}${u.pathname}`

  return (
    <a
      className={classNames("burn-history__tx-link", className)}
      href={url}
      target="_blank"
      rel="noreferrer">
      <div className="burn-history__tx-link-text">{prettyUrl}</div>
      <ArrowSquareOut size={14} className="burn-history__tx-link-icon" />
    </a>
  )
}

const BurnHistory = ({entries}) => (
  <div className="burn-history__container">
    <h2 className="burn-history__title">HISTORY</h2>
    <div className="burn-history__entries">
      {entries.map(({date, txUrl, amount, percentage}, idx) => (
        <div
          key={`entry-${idx}`}
          className="burn-history__entry">
          <div className={classNames(
            "burn-history__date-col",
            "burn-history__date",
          )}>
            {date}
          </div>
          <div className="burn-history__tx-link-col">
            <BurnTxLink url={txUrl} />
          </div>
          <div className={classNames(
            "burn-history__amount-col",
            "burn-history__amount",
          )}>
            {amount} $HM
          </div>
          <div className={classNames(
            "burn-history__perc-col",
            "burn-history__perc",
          )}>
            {percentage}%
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default BurnHistory
