import React from "react"
import {useNavigate} from "react-router-dom"
import {useQueryClient} from "@tanstack/react-query"

import "features/home/HomePage.css"
import Button from "components/Button/Button"
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner"
import PageContainer from "components/PageContainer/PageContainer"
import PageHeader from "components/PageHeader/PageHeader"
import PoweredByBadge from "components/PoweredByBadge/PoweredByBadge"
import HomeHoldiumCard from "features/home/HomeHoldiumCard"
import HomeDropSummary from "features/home/HomeDropSummary"
import network from "utils/network"
import {useQueryWithErrorHandling} from "utils/query"

const homeRequest = () => network.get("/home")

const HomePage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {data, isLoading, isError} = useQueryWithErrorHandling({
    queryKey: ["home"],
    queryFn: homeRequest,
  })

  const invalidateHomeQuery = () => {
    queryClient.invalidateQueries({queryKey: ["home"]})
  }

  if (isLoading) {
    return (
      <PageContainer className="home-page__center-content">
        <LoadingSpinner />
      </PageContainer>
    )
  }

  if (isError) {
    return (
      <PageContainer className="home-page__center-content">
        <p>Oops, something went wrong!</p>
      </PageContainer>
    )
  }

  const {header, holdiumCard, dropSummary} = data.body

  return (
    <PageContainer>
      <PageHeader {...header} onWalletConnect={invalidateHomeQuery} />
      <div className="home-page__content">
        <HomeHoldiumCard {...holdiumCard} />
        {dropSummary && <HomeDropSummary {...dropSummary} />}
        <div className="home-page__buttons">
          <Button
            className="home-page__button"
            onClick={() => navigate("/burn-tracker")}
            isSecondary>
            Burn tracker
          </Button>
          <Button
            className="home-page__button"
            onClick={() => navigate("/leaderboard")}>
            View leaderboard
          </Button>
        </div>
        <PoweredByBadge className="home-page__powered-by" />
      </div>
    </PageContainer>
  )
}

export default HomePage
