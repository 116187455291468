import React from "react"
import classNames from "classnames"

import "components/TextWithIcon/TextWithIcon.css"

const TextWithIcon = ({
  className,
  text,
  textClassName,
  iconLeft,
  iconLeftClassName,
  iconRight,
  iconRightClassName,
}) => {
  return (
    <div className={classNames("text-with-icon__container", className)}>
      {iconLeft && (
        <span className={iconLeftClassName}>
          {iconLeft}
        </span>
      )}
      <span className={classNames(textClassName)}>{text}</span>
      {iconRight && (
        <span className={iconRightClassName}>
          {iconRight}
        </span>
      )}
    </div>
  )
}

export default TextWithIcon
