import React from "react"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"
import {RouterProvider} from "react-router-dom"
import {ConnectionProvider, WalletProvider} from "@solana/wallet-adapter-react"
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base"
import {clusterApiUrl} from "@solana/web3.js"

import "global.css"
import {router} from "router"

const queryClient = new QueryClient()

const solanaNetwork = WalletAdapterNetwork.Mainnet
const solanaEndpoint = clusterApiUrl(solanaNetwork)
const solanaWallets = []

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ConnectionProvider endpoint={solanaEndpoint}>
      <WalletProvider wallets={solanaWallets} autoConnect>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </WalletProvider>
    </ConnectionProvider>
  </QueryClientProvider>
)

export default App
