import React from "react"
import {XLogo, TelegramLogo} from "@phosphor-icons/react"
import classNames from "classnames"

import "components/PageContainer/PageContainer.css"

const PageContainer = ({
  className,
  children,
  showNavBar = true,
}) => (
  <div className="page-container">
    <div className="page-container__content-wrapper">
      {showNavBar && (
        <nav className="page-container__navbar">
          <ul className="page-container__navbar-section">
            <li>
              <a className="page-container__navbar-link" href="https://holdium.xyz/">
                What is Holdium?
              </a>
            </li>
          </ul>
          <ul className="page-container__navbar-section">
            <li>
              <a
                className={classNames(
                  "page-container__navbar-link",
                  "page-container__navbar-link--accent",
                )}
                href="https://jup.ag/swap/SOL-3DH8WZ6C2HdDR4J9ekYnTfai8Tx8mxn92mW8zcYmP4px"
                target="_blank"
                rel="noreferrer">
                Buy $HM
              </a>
            </li>
            <li>
              <a
                className="page-container__navbar-link"
                href="https://t.me/holdium"
                target="_blank"
                rel="noreferrer">
                <TelegramLogo size={16} />
              </a>
            </li>
            <li>
              <a
                className="page-container__navbar-link"
                href="https://x.com/holdiumxyz"
                target="_blank"
                rel="noreferrer">
                <XLogo size={16} />
              </a>
            </li>
          </ul>
        </nav>
      )}
      <div className="page-container__content-bg">
        <div className={classNames("page-container__content", className)}>
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default PageContainer
