import React, {useState} from "react"
import {useNavigate} from "react-router-dom"
import {ArrowLeft, Link} from "@phosphor-icons/react"

import "components/PageHeader/PageHeader.css"
import colors from "assets/colors"
import ConnectAnotherWalletModal from "features/connect/ConnectAnotherWalletModal"
import TextWithIcon from "components/TextWithIcon/TextWithIcon"

const PageHeader = ({onWalletConnect, walletsText, wallets, backUrl}) => {
  const navigate = useNavigate()
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <div className="page-header__container">
      <div>
        {backUrl && (
          <a className="page-header__back-link" onClick={() => navigate(backUrl)}>
            <ArrowLeft size={24} />
          </a>
        )}
      </div>
      <div className="page-header__wallets-link-container">
        {wallets && (
          <a
            className="page-header__wallets-link"
            onClick={() => setModalVisible(true)}>
            <TextWithIcon
              iconRight={<Link size={12} color={colors.mrGhost} />}
              text={walletsText}
            />
          </a>
        )}
      </div>
      {modalVisible && (
        <ConnectAnotherWalletModal
          visible={modalVisible}
          wallets={wallets}
          onDismiss={() => setModalVisible(false)}
          onWalletConnect={onWalletConnect}
        />
      )}
    </div>
  )
}

export default PageHeader
